
import { Patient } from '@/ts/interfaces/Patient';
import { State } from '@/ts/state/State';
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import { getPatientDisplay } from '../../helpers';
import { sendRequest } from '../main';

export default defineComponent({
    setup(){
        const store = useStore<State>();
        const patients = ref<Patient[]>([])
        onBeforeMount(async () => {
            try{
                const fetched = await sendRequest('/user/patients');
                patients.value = fetched.body;
            }catch(error){
                console.error(error);
            }
        });

        const setImpersonateDialog = inject<Function>('setImpersonateDialog')!;

        return {
            patients,
            getPatientDisplay,
            navSpec: computed(() => {
                const usersLinks: any[] = [
                    { to: '/users/create', text: 'יצירת משתמשים חדשים' }
                    // { to: '/users/existing', text: 'פעולות במשתמשים קיימים' }
                ]

                const isAdmin = store.state.auth0.roles.includes('admin');
                if(isAdmin)
                    usersLinks.push({ onClick: () => setImpersonateDialog(true), text: 'צפייה כמשתמש אחר' })

                return [
                    {
                        id: 'question-db',
                        name: 'מאגר השאלות',
                        links: [
                            { to: '/questions', text: 'צפייה בשאלות ושאלונים' },
                            { to: '/questions/delete', text:'מחיקת שאלות קיימות' },
                            { to: '/questions/new', text: 'הוספת שאלות חדשות' },
                            { to: '/questions/collection', text: 'יצירת מקבץ שאלות' },
                            { to: '/questions/collection/select', text:'עריכת מקבץ שאלות' }
                        ],
                        icon: { name: 'contact_support', color: '#b95339' }
                    },
                    {
                        id: 'users',
                        name: 'ניהול משתמשים',
                        links: usersLinks
                    }
                ]
            })
        }
    }
});
