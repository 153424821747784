import cats from '../../../assets/json/questionCategories.json';
import types from '../../../assets/json/questionTypes.json';
import fileTypes from '../../../assets/json/fileTypes.json';
import units from '../../../assets/json/units.json';
import comparisons from '../../../assets/json/comparisons.json';
import { preset, presetKeys } from '@/ts/state/NewQuestionsState';

export const getPreset = () => {
    const lists = { cats, types, fileTypes, units, comparisons };
    const preset = {} as preset;
    
    for(const key in lists){
        const l = key as presetKeys;
        preset[l] = {};
        for(const i in lists[l]){
            const item = (lists[l] as any)[i];
            const key = l !== 'comparisons' ? (item.name ?? i) : item.operator;
            preset[l][key] = item.display ?? item;
        }
    }
    return preset;
}
