import { isMultiChoice, isMultiChoiceOrNum } from '@/helpers';
import { namespacedToCommit, namespacedToDispatch } from '@/helpers/store';
import { condition, newQuestion, NewQuestionsState, option, Save } from '@/ts/state/NewQuestionsState';
import { State } from '@/ts/state/State';
import { ActionContext } from 'vuex';
import { getPreset } from './helpers';

export const get = {
    condition: (): condition => ({ type: '>', val: null, x: null, ditch: false }),
    option: (): option => ({ val: '', ditch: false }),
    valid: (): newQuestion['valid'] => ({ is: true, children: true, text: true, cat: true, type: true, condition: true, options: [], params: [], fileType: true }),
    data: (parentType: newQuestion['type'] = null): newQuestion => ({
        text: '',
        cat: '',
        type: '',
        unit: null,
        alt: false,
        na: true,
        allow_alt: false,
        fileType: '',
        options: [get.option(),get.option()],
        params: [get.option()],
        link: '',
        children: [],
        valid: get.valid(),
        canBeTop: parentType === null,
        getFrom: 'new' as newQuestion['getFrom'],
        condition: parentType === 'num' ? [get.condition()] : {},
        conditionType: 'or'
    }),
}

export const getChildren = (state: NewQuestionsState, level: number, activePath?: number[]) => {
    if(!activePath)
        activePath = state.activePath;
    let questions = state.questions;
    for(let l = 0 ; l < level; l++)
        questions = activePath.length ? questions[activePath[l]].children : [];
    return questions;
}

export const getParent = (state: NewQuestionsState, level: number) => {
    if(typeof level === undefined)
        level = state.set!.level
    return getChildren(state, level-1)[state.activePath[level-1]];
}

type pointer = { level: number, index: number };

const mutations = {
    addQuestion(state: NewQuestionsState, { level, children, parent }: { level: number, children: newQuestion[], parent?: newQuestion }){
        children.push(get.data(!level ? null : (parent ?? getParent(state, level)).type));
    },
    refreshTree(state: NewQuestionsState, level: number){
        state.activePath.splice(level);
        state.pagination.splice(level+1);
    },
    assign(state: NewQuestionsState, assign: Partial<NewQuestionsState>){
        Object.assign(state, assign);
    },
    addCondition(state: NewQuestionsState){
        const { condition } = state.set!.data;
        if(Array.isArray(condition))
            condition.push(get.condition());
    },
    removeOrigin(state: NewQuestionsState){
        delete state.set!.data.origin;
    },
    addOptions(state: NewQuestionsState, elem: 'options' | 'params' = 'options'){
        state.set!.data[elem].push(get.option());
    },
    
    setSaves(state: NewQuestionsState, saves: Save[]){
        state.saves = saves.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    },
    setQuestion(state: NewQuestionsState, { level, index, prop, val }: pointer & { prop: keyof newQuestion, val: any }){
        const children = getChildren(state, level);
        children[index][prop] = val as never;
    },
    deleteQuestion(state: NewQuestionsState, { level, index }: pointer){
        const children = getChildren(state, level);
        children.splice(index, 1);
    },
    setPagination(state: NewQuestionsState, { level, value }: { level: number, value: number }){
        state.pagination[level] = value;
    }
}

type Context = ActionContext<NewQuestionsState, State>
const actions = {
    toggleChildren({ state }: Context, { level = null, type, index }: { level: number | 'origin' | null, type?: newQuestion['type'], index?: number }){
        if(!isMultiChoiceOrNum({ type }) || (typeof type === 'string' && !type.length))
            return;
        if(typeof level === 'number'){
            level = level - 1;
            if(state.activePath.length && state.activePath[level] === index)
                return refreshTree(level);
        }else{
            const question = level === 'origin' ? state.set!.data.origin! : state.set!;
            if(level === null && !question.data.children.length){
                question.data.children = [get.data(question.data.type)];
                assign({ set: null });
            }else if(level === 'origin'){
                const { activePath, pagination } = (question as newQuestion['origin'])!;
                assign({ set: null, activePath, pagination });
            }

            level = question.level;
            index = question.index;
        }

        const node = getChildren(state, level)[index!];
        node.valid.children = true;
        if(level < state.activePath.length)
            refreshTree(level);
        setPagination({ level: level + 1, value: 0 });
        assign({ activePath: [ ...state.activePath, index! ] });
    },
    openSettings({ state }: Context, { level, index }: { level: number, index: number }){
        level = level - 1;
        switch(state.mode){
            case 'choose': {
                const { activePath, pagination, retainSet } = state;
                const slicePaths = state.activePath.length > level;

                retainSet.set!.data.origin = {
                    data: getChildren(state, level)[index],
                    activePath: activePath.slice(0, slicePaths ? level : undefined),
                    pagination: pagination.slice(0, slicePaths ? level + 1 : undefined),
                    level,
                    index
                }
                return assign({ ...retainSet, retainSet: {}, mode: 'set' });
            }
            case 'set': {
                const changes = {} as Partial<NewQuestionsState>;
                const origin = state.set?.data.origin;
                if(origin)
                    Object.assign(changes, {
                        activePath: [ ...origin.activePath ],
                        pagination: [ ...origin.pagination ],
                        set: { level: origin.level, index: origin.index, data: origin.data }
                    });
                else
                    changes.set = { data: getChildren(state, level)[index], level, index };

                changes.set!.data.valid.is = true;
                assign(changes);
                if(state.activePath[level] !== index)
                    refreshTree(level);
            }
        }
    },

    shift({ state }: Context, { level, dir, shiftTo }: { [key: string]: number }){
        setPagination({ level, value: shiftTo ?? (state.pagination[level] + dir) });
    },
    deleteOption({ state }: Context, { index, elem }: { index: number | string, elem: 'options' | 'params' | 'condition'}){
        /*fix required for when already has children!! Go over them and replace answer indices where necessary*/
        const obj = state.set!.data[elem];
        const isCondition = (obj: any): obj is condition[] => obj.type !== undefined;
        const isOption = (obj: any): obj is option[] => obj.type === undefined;
        if(!isOption(obj) && !isCondition(obj))
            return;

        if(typeof index === 'string')
            index = parseInt(index);
        
        if(!obj[index].ditch)
            return obj[index].ditch = true;

        obj.splice(index, 1);
        if(!isCondition(obj)){
            if((elem === 'options' && obj.length <= 1) || (elem === 'params' && !obj.length))
                obj.push(get.option());
        }else if(elem === 'condition' && !obj.length){
            obj.push(get.condition());
        }
    }
}

const toCommit = namespacedToCommit('questions');
const toDispatch = namespacedToDispatch('questions');

export const setSaves = toCommit(mutations.setSaves);
export const setQuestion = toCommit(mutations.setQuestion);
export const addQuestion = toCommit(mutations.addQuestion);
export const refreshTree = toCommit(mutations.refreshTree);
export const assign = toCommit(mutations.assign);
export const setPagination = toCommit(mutations.setPagination);
export const deleteQuestion = toCommit(mutations.deleteQuestion);

export const addCondition = toCommit(mutations.addCondition);
export const removeOrigin = toCommit(mutations.removeOrigin);

export const shift = toDispatch(actions.shift);
export const openSettings = toDispatch(actions.openSettings);
export const toggleChildren = toDispatch(actions.toggleChildren);
export const deleteOption = toDispatch(actions.deleteOption);

export default {
    namespaced: true,
    state: {
        questions: [get.data()] as newQuestion[],
        activePath: [],
        pagination: [0],
        set: null,
        retainSet: {},
        mode: 'set' as NewQuestionsState['mode'],
        saveId: null,
        ...getPreset(),
        saves: [],
    },
    mutations,
    actions
}
