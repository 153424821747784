import { createRouter, createWebHistory } from 'vue-router';
import Home from '../Home/index.vue';
import beforeEach from './beforeEach';
import NestedView from './NestedView.vue';
import Questionnaires from '../Questionnaires/index.vue';

const nonPatient = ['admin', 'physician'];

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: Home, name: 'home' },
        { path: '/login', component: () => import('../User/Login.vue'), meta: { allowedRoles: ['guest'] } },
        {
            path: '/signup',
            component: NestedView,
            children: [
                { path: 'invalid', component: () => import('../User/Signup/Message.vue') },
                { path: 'expired', component: () => import('../User/Signup/Message.vue') },
                { path: 'completed', component: () => import('../User/Signup/Message.vue') },
                { path: ':token', component: () => import('../User/Signup/index.vue') }
            ],
            meta: { allowedRoles: ['guest'] }
        },
        {
            path: '/questions/collection',
            component: NestedView,
            children: [
                { path: ':collectionId?', component: () => import('../Questions/Collection/CreateOrModify.vue') },
                { path: 'select', component: () => import('../Questions/Collection/Select.vue') },
            ],
            meta: { allowedRoles: nonPatient }
        },
        {
            path: '/questions',
            component: NestedView,
            children: [
                { path: '', component: () => import('../Questions/ViewChoose.vue') },
                { path: 'delete', component: () => import('../Questions/Delete.vue') },
                // { path: 'edit', component: () => import('../Questions/Edit.vue') },
                { path: 'view', component: () => import('../Questions/View.vue') },
                { path: 'new', component: () => import('../Questions/New/index.vue') },
                { path: 'edit', component: () => import('../Questions/Edit.vue') }
            ],
            meta: { allowedRoles: nonPatient }
        },
        {
            path: '/:patientId/questionnaires',
            component: Questionnaires,
            children: [
                { path: '', component: () => import('../Questionnaires/View/index.vue'), meta: { pageTitle: 'שאלות במעקב' } },
                { path: 'questions/:questionnaireId', component: () => import('../Questionnaires/HandleQuestions.vue'), meta: { pageTitle: '{questionnaire_name} - בחירת שאלות' } },
                { path: 'data/:questionnaireId?', component: () => import('../Questionnaires/Data/index.vue'), meta: { pageTitle: 'צפייה בתשובות' } }
            ]
        },
        { path: '/respond', component: () => import('../Home/QuestionnaireRespond.vue'), meta: { allowedRoles: ['patient'] } },
        {
          path: '/users',
          component: NestedView,
          children: [
            { path: 'create/:qualifier?', component: () => import('../User/Create/index.vue'), meta: { allowedRoles: nonPatient } },
            { path: 'setintake', component: () => import('../User/SetIntake.vue'), meta: { allowedRoles: nonPatient } },
          ]
        },
        { path: '/intake', component: () => import('../Patient/Intake.vue'), meta: { allowedRoles: ['patient'] }  },
    ],
})

router.beforeEach(beforeEach);

export default router;
