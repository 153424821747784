<template>
    <div id='physicianView'>
        <!-- <div class='sidebar'>
            
        </div> -->
        <page-template headerText='המטופלים שלך' :sidebarProps='{ navSpec }'>
            <div class='patientCardsContainer'>
                <div class='patientCard card' v-for='patient of patients' @click='$router.push(`/${patient.id}/questionnaires`)' :key='patient.id'>
                    {{ getPatientDisplay(patient) }}
                    <div class='patientActions'>
                        
                    </div>
                </div>
            </div>
        </page-template>
        
    </div>
</template>

<script lang='ts'>
import { Patient } from '@/ts/interfaces/Patient';
import { State } from '@/ts/state/State';
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import { getPatientDisplay } from '../../helpers';
import { sendRequest } from '../main';

export default defineComponent({
    setup(){
        const store = useStore<State>();
        const patients = ref<Patient[]>([])
        onBeforeMount(async () => {
            try{
                const fetched = await sendRequest('/user/patients');
                patients.value = fetched.body;
            }catch(error){
                console.error(error);
            }
        });

        const setImpersonateDialog = inject<Function>('setImpersonateDialog')!;

        return {
            patients,
            getPatientDisplay,
            navSpec: computed(() => {
                const usersLinks: any[] = [
                    { to: '/users/create', text: 'יצירת משתמשים חדשים' }
                    // { to: '/users/existing', text: 'פעולות במשתמשים קיימים' }
                ]

                const isAdmin = store.state.auth0.roles.includes('admin');
                if(isAdmin)
                    usersLinks.push({ onClick: () => setImpersonateDialog(true), text: 'צפייה כמשתמש אחר' })

                return [
                    {
                        id: 'question-db',
                        name: 'מאגר השאלות',
                        links: [
                            { to: '/questions', text: 'צפייה בשאלות ושאלונים' },
                            { to: '/questions/delete', text:'מחיקת שאלות קיימות' },
                            { to: '/questions/new', text: 'הוספת שאלות חדשות' },
                            { to: '/questions/collection', text: 'יצירת מקבץ שאלות' },
                            { to: '/questions/collection/select', text:'עריכת מקבץ שאלות' }
                        ],
                        icon: { name: 'contact_support', color: '#b95339' }
                    },
                    {
                        id: 'users',
                        name: 'ניהול משתמשים',
                        links: usersLinks
                    }
                ]
            })
        }
    }
});
</script>

<style lang="scss">
#physicianView {
    display: contents;
    & header {
        width: calc(60vw * 0.85);
    }

    .sidebarBox {
        z-index: 3000;

        & #addPatient {
            padding: 1rem 1rem;
            cursor: pointer;
            display: flex;
            align-items: center;

            & span {
                color: #3b8883;
            }

            &:hover {
                background-color: $swatchB;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.patientCardsContainer {
    display: flex;
    flex-direction: column;
    width: $mainWidth;
}

.patientCard, h1 {
    margin: 1.5rem auto 0;
    width: 80%;
}

.patientCard {
    min-height: 6rem;
    cursor: pointer;
    padding: 1rem;
    position: relative;

    &:hover {
        background-color: $swatchC;
    }

    & .patientActions {
      height: 100%;
      position: absolute;
      left: 0.5rem;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      & img {
        width: 2rem;
      }

      & .viewFollowing {
        filter: grayscale(50%) brightness(110%);
        width: 1.7rem;
      }
    }
}
</style>