import { sendRequest } from '@/SharedChart/main';
import module from './module';
import store from '@/store';

export async function getPatient(patientId: string){
    try{
        const response = await sendRequest(`/patient/get`, undefined, undefined, { headers: { 'X-PATIENT-ID': patientId } });
        if(!store.hasModule('patient'))
            store.registerModule('patient', module);
        store.commit('patient/setPatient', response.body);
    }catch(error){
        console.error(error);
        throw error;
    }
}