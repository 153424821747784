
import { isMultiChoiceOrNum } from '@/helpers';
import { newQuestion } from '@/ts/state/NewQuestionsState';
import { ComponentPublicInstance, defineComponent, PropType } from '@vue/runtime-core';
import EyeTooltip from './EyeTooltip.vue';
import { deleteQuestion, openSettings, refreshTree, setPagination, toggleChildren } from './module';
import { isNewQuestion, treeRow } from './TreeRow.vue';

export default defineComponent({
    props: {
        pagination: { type: Number, required: true },
        index: { type: Number, required: true },
        question: { type: Object as PropType<newQuestion>, required: true }
    },
    emits: ['textChange'],
    components: { EyeTooltip },
    computed: {
        level(){
            return (this.$parent as ComponentPublicInstance<treeRow>).level;
        },
        children(){
            return (this.$parent as ComponentPublicInstance<treeRow>).children;
        },
        parent(){
            return (this.$parent as ComponentPublicInstance<treeRow>).parent;
        },
        comparisons(){
            return this.$store.state.questions!.comparisons;
        },
        mode(){
            return this.$store.state.questions!.mode;
        },
        retainSet(){
            return this.$store.state.questions!.retainSet;
        },
        activeIndex(){
            return this.$store.state.questions!.activePath[this.level-1]
        },
        canHaveChildren(){
            return isMultiChoiceOrNum(this.question);
        },
        origin(){ return this.question.origin },
        getConditions(){
            if(typeof this.question.condition === 'undefined' || !this.parent)
                return null;

            const { options } = this.parent;
            const { condition } = this.question;
    
            if(!Array.isArray(condition)){
                const answers = [];
                for(let k in condition)
                    if(condition[k])
                        answers.push(options[k].val);
                if(answers.length)
                    return `בעקבות ה${answers.length > 1 ? 'תשובות' : 'תשובה'}: ${answers.join(', ')}`;
            }else{
                const qualifiers = [];
                for(let c of condition) if(c.val!==null){
                    if(c.type !== '<>'){
                        qualifiers.push(this.comparisons[c.type] + c.val);
                    }else if(c.x !== null){
                        const [start, end] = c.val > c.x ? [c.x, c.val] : [c.val, c.x]
                        qualifiers.push(`בטווח בין ${start} ל-${end}`);
                    }
                }
                if(qualifiers.length)
                    return `בעקבות תשובה ${qualifiers.join(', ')}`;
            }

            return '';
        },
        howmany(){
            const count = this.question.children.length;
            return count === 1 ? 'שאלה אחת עוקבת' : `${count} שאלות עוקבות`;
        },
        isParent(){
            if(!this.retainSet.activePath)
                return null;

            for(let l=0; l < this.level; l++)
                if(this.index !== this.retainSet.activePath[l])
                    return false;
            return this.index !== this.retainSet.activePath[this.level];
        },
        rightSeparator(){
            const index = this.index - this.pagination;
            const activeIndex = this.activeIndex - this.pagination;
            if(!index)
                return false;
            if(activeIndex === index && this.question.children.length)
                return true;

            const activeQuestion = this.children[this.activeIndex];
            if(isNewQuestion(activeQuestion)){
                if(activeIndex <= index && activeQuestion.children.length > index)
                    return true
                if(activeIndex === 2 && activeQuestion.children.length)
                    return true;
            }

            return false;
        },
        leftSeparator(){
            const index = this.index - this.pagination;
            const activeIndex = this.activeIndex - this.pagination;
            if(index >= 2)
                return false;
            if(activeIndex === index && this.question.children.length - 1 > index )
                return true;

            const activeQuestion = this.children[this.activeIndex];
            if(isNewQuestion(activeQuestion)){
                if(activeIndex > index && activeQuestion.children.length)
                    return true;
                if(activeIndex === 0 && activeQuestion.children.length > 2)
                    return true;
            }

            return false;
        }
    },
    methods: {
        openSettings,
        toggleChildren,
        deleteQuestion(){
            if(this.mode === 'choose' || !confirm("אתם בטוחים שאתם רוצים למחוק את השאלה הזו?"))
                return;

            const level = this.level - 1;
            const { activePath, pagination } = this.$store.state.questions!;

            deleteQuestion({ level, index: this.index });
            setPagination({ level, value: pagination[level] ? pagination[level] - 1 : 0 });
            if(activePath[level] === this.index)
                refreshTree(level);
        },
    }
});
