import { namespacedToCommit, namespacedToDispatch } from '@/helpers/store';
import { ChooserSelection, ChooserState, questionsByCat } from '@/ts/interfaces/ChooserState';
import { State } from '@/ts/state/State';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { ActionContext } from 'vuex';
import { sendRequest } from '@/SharedChart/main';

const cleanLocal = () => ({
    selected: [],
    keepSelected: false
})

const cleanState = () => ({
    ...cleanLocal(),
    questionsByCat: {},
    collections: [],
    questions: [],
    legends: [],
    previousRoute: {},
});

const mutations = {
    assignFetched: (state: ChooserState, assign: ChooserState) => Object.assign(state, assign),
    setPreviousRoute: (state: ChooserState, route: RouteLocationNormalizedLoaded) => state.previousRoute = route,

    reset: (state: ChooserState) => Object.assign(state, cleanLocal()),
    setSelected: (state: ChooserState, { selected, legends }: ChooserSelection) => Object.assign(state, { selected, legends, keepSelected: true }),
    toggleSelected: (state: ChooserState, { id, activate = false }: { id: string | number, activate?: boolean }) => {
        const index = state.selected.findIndex(i => i === id);
        if(index >= 0){
            if(activate !== true)
                state.selected.splice(index, 1);
        }else{
            state.selected.push(id);
        }
    },
    reorderSelected: (state: ChooserState, { index, dir }: { index: number, dir: number }) => {
        const id = state.selected[index];
        state.selected.splice(index, 1);
        state.selected.splice(index + dir, 0, id);
    },
    addLegend: (state: ChooserState, index: number) => {
        state.legends.push('');
        state.selected.splice(index, 0, 'l' + (state.legends.length - 1));
    },
    deleteLegend: (state: ChooserState, index: number) => {
        state.selected.splice(index, 1);
    }
}

type Context = ActionContext<ChooserState, State>
const actions = {
    noneSelected({ commit }: Context){
        commit('setToast', { text: 'יש לבחור שאלות כדי להמשיך' }, { root: true });
    },
    async load({ commit, state }: Context){
        try {
            const response = await sendRequest('/questions/chooser');
            const questionsByCat = {} as questionsByCat;
            const { questions } = response.body;
            for(const q in questions){
                const { cat } = questions[q];
                if(!questionsByCat[cat])
                    questionsByCat[cat] = {};
                questionsByCat[cat][q] = questions[q];
            }

            const assign = { ...cleanState(), ...response.body, questionsByCat }
            if(state.keepSelected){
                assign.selected = [...state.selected];
                assign.legends = [...state.legends];
            }

            commit('assignFetched', assign);
        } catch(error) {
            console.error(error)
        }
    }
}

const toCommit = namespacedToCommit('chooser');
const toDispatch = namespacedToDispatch('chooser');

export const assignFetched = toCommit(mutations.assignFetched);
export const setPreviousRoute = toCommit(mutations.setPreviousRoute);
export const reset = toCommit(mutations.reset);
export const setSelected = toCommit(mutations.setSelected);
export const toggleSelected = toCommit(mutations.toggleSelected);
export const reorderSelected = toCommit(mutations.reorderSelected);
export const addLegend = toCommit(mutations.addLegend);
export const deleteLegend = toCommit(mutations.deleteLegend);

export const load = toDispatch(actions.load);


export default {
    namespaced: true,
    state: cleanState(),
    mutations,
    actions
};
