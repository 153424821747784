
import { defineComponent } from '@vue/runtime-core';
import questionCategories from '../../assets/json/questionCategories.json'
import { toggleSelected } from './module';

export default defineComponent({
    data: () => ({ category: 'all', search: '' }),
    computed: {
        questions(){
            const { questions, questionsByCat } = this.$store.state.chooser!;
            const all = this.category === 'all' ? questions : Object.values(questionsByCat[this.category]);
            return all.filter(question => question.cat !== null && (!this.search.length || question.text.includes(this.search)))
        },
        categoryList(){
            const { questionsByCat } = this.$store.state.chooser!;
            const categories = Object.entries(questionCategories).filter(([cat]) => questionsByCat[cat])
            return [['all', 'כל הקטגוריות']].concat(categories)
        },
        selected(){
            return this.$store.state.chooser!.selected;
        }
    },
    methods: {
        toggleSelected
    }
});
