<template>
    <div class='toggleButton'>
        <img v-if='node.type === "questionnaire"' src='/images/questionnaire.jpg' :style='{ width: "1.5rem", marginRight: "-.6rem", marginTop: "-.1rem" }' />
        <span v-else-if='node.type === "collection"' class='material-icons-round'>category</span>
        <span v-else class='material-icons-round'>question_mark</span>
        <span
            @click.stop='toggleSelection($event)'
            :class='{ customChartBullet: true, isSelected: node.selected }'
            :style='{ borderRadius: bulletBorderRadius }'
        />
        <div @click='toggle' :style='{ cursor: !toggle && "default" }'>
            <div class='toggleText' :class='classes' @mouseenter='showQuestionTooltip($event)' @mouseleave='hideQuestionTooltip'>
                {{ preparedText.short }}
            </div>
            <collapsible-triangle v-if='toggle' fill='#999' :isActive='isActive' />
            <div v-else :style='{ width: "1.1rem" }' />
        </div>
    </div>
</template>

<script lang="ts">
import { toggleChildren, toggleParent } from "./helpers";
import { treeNode } from "@/ts/state/ViewQuestionnaireState";
import { defineComponent, PropType } from "@vue/runtime-core";
import { setQuestionTooltip, setSelectionTooltip, toggleSelection } from '../module';

export default defineComponent({
    props: {
        classes: { type: Object },
        toggle: { type: Function },
        isActive: { type: Boolean },
        node: { type: Object as PropType<treeNode>, required: true }
    },
    data: () => ({ tooltipTimeout: null as any }),
    computed: {
        preparedText(){
            const parsed = this.$parse(this.node.text);
            const maxLen = 48;
            if(parsed.length > maxLen)
                return { long: parsed, short: parsed.slice(0, maxLen).trim() + '...' };
            
            return { short: parsed };                
        },
        bulletBorderRadius(){
            return (this.node.level % 2 ? '6px' : '50%') + ' !important';
        }
    },
    methods: {
        hideQuestionTooltip(){
            if(this.tooltipTimeout){
                clearTimeout(this.tooltipTimeout);
                this.tooltipTimeout = null;
            }else if(this.$store.state.viewQuestionnaire?.questionTooltip){
                setQuestionTooltip();
            }
        },
        showQuestionTooltip(event: Event){
            if(this.preparedText.long)
                this.tooltipTimeout = setTimeout(() => {
                    setQuestionTooltip({ text: this.preparedText.long!, event });
                    this.tooltipTimeout = null;
                }, 600);
        },
        hideSelectionTooltip(){
            if(this.$store.state.viewQuestionnaire?.selectionTooltip)
                setSelectionTooltip();
        },
        toggleSelection(event: Event){
            if(this.node.type === 'question' && this.node.children?.length){
                if(this.$store.state.viewQuestionnaire!.selectionTooltip?.node !== this.node)
                    return setSelectionTooltip({ node: this.node, event });
                else
                    return setSelectionTooltip();
            }
            
            toggleSelection(this.node.path);
            toggleChildren(this.node.children, this.node.selected);
            toggleParent(this.node.parent, this.node.selected);
        }
    }
});
</script>

<style lang="scss" scoped>
    .toggleButton {
        display: flex;
        align-items: flex-start;
        padding: .2rem 0 .3rem;
        text-align: justify;

        & > .material-icons-round {
            background-color: #fff;
            padding: .1rem;
            font-size: 1.2rem;
            margin-left: .25rem;
            margin-right: -.75rem;
        }

        & .collapsibleTriangle {
            margin-right: auto;
            &.isActive {
                --translateX: -.5rem;
            }

            &:not(.isActive){
                --translateY: .5rem;
            }
        }

        & .toggleText {
            padding-left: 1rem;
            line-height: 1.4rem;
        }
            
        & > div:last-child {
            display: flex;
            align-items: flex-start;
            flex: 1;
            cursor: pointer;
        }

        & .customChartBullet {
            transform: translateY(.3rem);
            cursor: pointer;
            user-select: none;
        }
    }
</style>