import { App } from '@vue/runtime-core';

import Chooser from './Chooser/index.vue';
import QuestionSelectionItem from './Questionnaires/Data/QuestionSelection/Item.vue';
import TreeRow from './Questions/New/TreeRow.vue';

// import { NavPopper } from './components';
import {
    Collapsible,
    SubmitButton,
    PageTemplate,
    SidebarBox,
    SwitchComponent,
    Skeleton,
    CollapsibleTriangle,
    Dialog
} from '@/components';

import { auth0, Auth0Plugin, FAKE_AUTH0 } from '@/plugins/Auth0Plugin';
import router from './router';
import { makeSendRequest } from '@/helpers';
import { DraggableDirective } from '@braks/revue-draggable';
import store from '@/store';

export const sendRequest = makeSendRequest([
    async headers => {
        if(!headers.has('Authorization') && store.state.auth0.isAuthenticated && !FAKE_AUTH0)
            headers.append('Authorization', 'Bearer ' + (await auth0.client!.getTokenSilently()))
    },
    headers => {
        const { patientId } = router.currentRoute.value.params;
        if(patientId)
            headers.append('X-PATIENT-ID', patientId as string);
    },
    headers => {
        const { impersonating } = store.state.auth0;
        if(impersonating) headers.append('X-IMPERSONATING', impersonating.id);
    }
]);

const appSetup = (app: App<Element>) => {
    app.config.globalProperties.$request = sendRequest;
    
    app.use(Auth0Plugin(router))
        .directive('draggable', DraggableDirective)
        .component('collapsible', Collapsible)
        .component('collapsible-triangle', CollapsibleTriangle)
        .component('page-template', PageTemplate)
        .component('sidebar-box', SidebarBox)
        .component('submit-button', SubmitButton)
        .component('switch-component', SwitchComponent)
        .component('skeleton', Skeleton)
        .component('chooser', Chooser)
        .component('question-selection-item',  QuestionSelectionItem)
        .component('tree-row', TreeRow)
        .component('dialog-component', Dialog);

    return { router, app };
}

export { appSetup as default, router };