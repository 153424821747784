<template>
    <div class='item' :style='{ gridRowStart: level }'>
        <div class='branch top' :class='{ open: level > 1 && !question.placeholder }'>
            <div></div>
            <div></div>
        </div>
        <div class='wrapper' v-if='!question.placeholder'>
            <div v-if='!origin || origin.data' class='question' :class="{ isActive: activeIndex === index }">
                <div class='howmany' v-if='getConditions'>
                    {{ getConditions }}
                </div>
                <div class='txt'>
                    <eye-tooltip :q='question' :level='level' :index='index' />
                    <textarea v-if='!origin || question.getFrom==="new"' :value='question.text' @change='e => $emit("textChange", e.target.value)' />
                    <textarea v-else disabled='disabled' :value='Object.keys(origin).length > 0 && origin.data.text' />
                </div>
                <div class='howmany'>
                    <span v-if='!origin'>{{ howmany }}</span>
                    <span v-else>שאלה ממיקום אחר בעץ</span>
                </div>
                <div class='buttonBar'>
                    <div :class='{transparent: mode==="choose"}' @click='deleteQuestion'>
                        <img src='/images/trash.png' width='18'/>
                    </div>
                    <div v-show='mode==="set" || (mode==="choose" && !origin && !isParent)' @click.stop='openSettings({ level, index })'>
                        <span :class='{ redDot: question.valid.is === false }'></span>
                        <img src='/images/gear-icon.png' width='17' v-if='mode === "set"'/>
                        <img src='/images/select-arrow.png' width='17' v-else/>
                    </div>
                    <div
                        :class='{ stay: activeIndex === index, transparent: !canHaveChildren }'
                        @click='toggleChildren({ level, index, type: question.type })'
                    >
                        <span :class='{ redDot: question.valid.children === false }'></span>
                        <img src='/images/tree-icon.png' width='22'/>
                    </div>
                </div>
            </div>
            <div v-else class='question chooseMode'>חפשו את השאלה שרציתם לקשר אליה - לחיצה על סימן החץ תחזיר אתכם לכאן ותיצור את הקישור</div>
        </div>
        <div v-else class='placeholder'></div>
        <div class='branch' :class='{ open: activeIndex === index && question.children.length }'>
            <div :class='{ separator: rightSeparator }'></div>
            <div :class='{ separator: leftSeparator }'></div>
        </div>
    </div>
    <tree-row
        v-if='activeIndex == index && !question.placeholder'
        :level='level+1'
        :children='question.children'
        :parent='question'
    />
</template>

<script lang='ts'>
import { isMultiChoiceOrNum } from '@/helpers';
import { newQuestion } from '@/ts/state/NewQuestionsState';
import { ComponentPublicInstance, defineComponent, PropType } from '@vue/runtime-core';
import EyeTooltip from './EyeTooltip.vue';
import { deleteQuestion, openSettings, refreshTree, setPagination, toggleChildren } from './module';
import { isNewQuestion, treeRow } from './TreeRow.vue';

export default defineComponent({
    props: {
        pagination: { type: Number, required: true },
        index: { type: Number, required: true },
        question: { type: Object as PropType<newQuestion>, required: true }
    },
    emits: ['textChange'],
    components: { EyeTooltip },
    computed: {
        level(){
            return (this.$parent as ComponentPublicInstance<treeRow>).level;
        },
        children(){
            return (this.$parent as ComponentPublicInstance<treeRow>).children;
        },
        parent(){
            return (this.$parent as ComponentPublicInstance<treeRow>).parent;
        },
        comparisons(){
            return this.$store.state.questions!.comparisons;
        },
        mode(){
            return this.$store.state.questions!.mode;
        },
        retainSet(){
            return this.$store.state.questions!.retainSet;
        },
        activeIndex(){
            return this.$store.state.questions!.activePath[this.level-1]
        },
        canHaveChildren(){
            return isMultiChoiceOrNum(this.question);
        },
        origin(){ return this.question.origin },
        getConditions(){
            if(typeof this.question.condition === 'undefined' || !this.parent)
                return null;

            const { options } = this.parent;
            const { condition } = this.question;
    
            if(!Array.isArray(condition)){
                const answers = [];
                for(let k in condition)
                    if(condition[k])
                        answers.push(options[k].val);
                if(answers.length)
                    return `בעקבות ה${answers.length > 1 ? 'תשובות' : 'תשובה'}: ${answers.join(', ')}`;
            }else{
                const qualifiers = [];
                for(let c of condition) if(c.val!==null){
                    if(c.type !== '<>'){
                        qualifiers.push(this.comparisons[c.type] + c.val);
                    }else if(c.x !== null){
                        const [start, end] = c.val > c.x ? [c.x, c.val] : [c.val, c.x]
                        qualifiers.push(`בטווח בין ${start} ל-${end}`);
                    }
                }
                if(qualifiers.length)
                    return `בעקבות תשובה ${qualifiers.join(', ')}`;
            }

            return '';
        },
        howmany(){
            const count = this.question.children.length;
            return count === 1 ? 'שאלה אחת עוקבת' : `${count} שאלות עוקבות`;
        },
        isParent(){
            if(!this.retainSet.activePath)
                return null;

            for(let l=0; l < this.level; l++)
                if(this.index !== this.retainSet.activePath[l])
                    return false;
            return this.index !== this.retainSet.activePath[this.level];
        },
        rightSeparator(){
            const index = this.index - this.pagination;
            const activeIndex = this.activeIndex - this.pagination;
            if(!index)
                return false;
            if(activeIndex === index && this.question.children.length)
                return true;

            const activeQuestion = this.children[this.activeIndex];
            if(isNewQuestion(activeQuestion)){
                if(activeIndex <= index && activeQuestion.children.length > index)
                    return true
                if(activeIndex === 2 && activeQuestion.children.length)
                    return true;
            }

            return false;
        },
        leftSeparator(){
            const index = this.index - this.pagination;
            const activeIndex = this.activeIndex - this.pagination;
            if(index >= 2)
                return false;
            if(activeIndex === index && this.question.children.length - 1 > index )
                return true;

            const activeQuestion = this.children[this.activeIndex];
            if(isNewQuestion(activeQuestion)){
                if(activeIndex > index && activeQuestion.children.length)
                    return true;
                if(activeIndex === 0 && activeQuestion.children.length > 2)
                    return true;
            }

            return false;
        }
    },
    methods: {
        openSettings,
        toggleChildren,
        deleteQuestion(){
            if(this.mode === 'choose' || !confirm("אתם בטוחים שאתם רוצים למחוק את השאלה הזו?"))
                return;

            const level = this.level - 1;
            const { activePath, pagination } = this.$store.state.questions!;

            deleteQuestion({ level, index: this.index });
            setPagination({ level, value: pagination[level] ? pagination[level] - 1 : 0 });
            if(activePath[level] === this.index)
                refreshTree(level);
        },
    }
});
</script>

<style lang='scss' scoped>  
.branch {
    @include flexbox();
    min-height: 3rem;
    width: 100%;
    & > div {
        width: 50%;
        flex-grow: 1;
    }
    &.open > div {
        &:first-child { border-left: 1px black solid; }
        &:last-child  { border-right: 1px black solid; }
    }
    &.top {
        flex-grow: 1;
    }
}
.separator {
    border-bottom: 1px black solid;
}

.question, .placeholder {
    min-height: 9.5rem;
}

.question {
    width: 16rem;
    border-radius: $corner;
    background-color: #fff;
    border: 2px #fff solid;

    @include flexbox(between,false,column);
    @include shadow(0.1,0.45);

    & .txt {
        padding: 0.3rem 0.75rem 0;
        text-align: center;
        @include flexbox(false, flex-start);

        &.condition {
            margin: 0 auto;
            font-size: 0.75rem;
            color: #bbb;
            margin: 0px;
        }
    }
    & .howmany {
        text-align: center;
        font-size: 0.75rem;
        color: #aaa;
    }
    & textarea {
        padding: 0.25rem;
        background-color: #fafafa;
        border-radius: 0.2rem;
        text-align: right;
        line-height: 1.5;
        min-height: 4rem;
        width: 12rem;

        &:disabled {
            outline: none;
            border: none;
            min-height: 4.25rem;
        }
    }
    &.isActive {
        border-color: #fcc;
    }
    &.chooseMode {
        background-color: $bgTwo;
        padding: 0.6rem;
        @include flexbox(center-all);
    }
}
</style>
