
import { treeNode } from "@/ts/state/ViewQuestionnaireState";
import { defineComponent, PropType } from "@vue/runtime-core";
import ToggleButton from './ToggleButton.vue';

export default defineComponent({
    props: {
        node: { type: Object as PropType<treeNode>, required: true }
    },
    components: { ToggleButton }
});
