import { treeNode } from "@/ts/state/ViewQuestionnaireState";

export const toggleChildren = (children: treeNode[] | undefined, selected: boolean) => {
    if(children) for(const child of children){
        child.selected = selected;
        toggleChildren(child.children, selected);
    }
}

const allSelected = (nodes: treeNode[] | undefined) => {
        if(nodes)
            for(const node of nodes)
                if(!node.selected || !allSelected(node.children))
                    return false;
        return true;
}

export const toggleParent = (parent: treeNode | undefined, selected: boolean): void => {
    if(!parent)
        return;

    if(parent.selected === selected || parent.type === 'question')
        return toggleParent(parent.parent, selected);

    if(selected && !allSelected(parent.children)) return;
    if(!selected && !parent.selected) return;
    
    parent.selected = selected;
    toggleParent(parent.parent, selected);
}
// selectParent(this.node);