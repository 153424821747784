<template>
    <!-- <button @click='remind'>REMIND</button> -->
    <physician-view v-if='isPhysician' />
    <div id='patientView' v-else-if='isPatient'>
        <questionnaire-select />
    </div>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import PhysicianView from './PhysicianView.vue';
import QuestionnaireSelect from './QuestionnaireSelect.vue';

export default defineComponent({
    components: { PhysicianView, QuestionnaireSelect },
    computed: {
        isPhysician(){
            return this.$store.state.auth0.roles.includes('physician');
        },
        isPatient(){
            return this.$store.state.auth0.roles.includes('patient');
        }
    },
    methods: {
        remind(){
            fetch('http://localhost:3000/remind', {
                headers: {
                    'Authorization': 'Api-Key GxppZnhrhYj0R0Q1lxCcwF95NWLcSPwWVdBv4XhhU1LcZeHVqthk9byJOJQITYCWfbtg9Fqy',
                    'X-TIMEZONE-OFFSET': new Date().getTimezoneOffset().toString()
                },
                credentials: 'include',
                method: 'put'
            })
        }
    }
});
</script>

<style lang="scss" scoped>
#patientView {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
</style>