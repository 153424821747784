import { RouteLocation } from 'vue-router';
import { getPatient } from '../Patient/helpers';
import store, { setToast } from '@/store';
import { role } from '@/ts/state/Auth0State';

const beforeEach = async (to: RouteLocation) => {
    requestAnimationFrame(() => document.querySelector('main')?.scrollTo(0,0));
    const allowedRoles = to.meta.allowedRoles as (role[] | undefined);

    if(store.state.auth0.loading) // If user is loading, wait before determining proper routing
        await new Promise(resolve => {
            store.watch(state => state.auth0.loading, (value: boolean) => {
                if(value === false)
                    resolve(null);
            });
        });

    const { isAuthenticated, roles: userRoles } = store.state.auth0;

    if(!isAuthenticated){
        if(allowedRoles?.includes('guest'))
            return;
        else
            return '/login';
    }

    if(allowedRoles && !allowedRoles.find(role => userRoles.includes(role))){
        setToast({ text: 'אין לך גישה לעמוד שניסית להכנס אליו', type: 'error' })
        return '/';
    }

    if(to.params.patientId && !store.hasModule('patient')){
        try {
            await getPatient(to.params.patientId as string);
        } catch(error) {
            console.error(error);
            return false;
        }
    }
    
    return;
    const { user } = store.state;
    // if(!user.confirmed_at){
    //     if(to.path.includes('newpass')) return;
    //     return '/users/newpass';
    // }
    
    if(user.type === 'patient'){
        const allowedPatientRoutes = ['/respond', '/users/newpass', '/intake'];
        if(to.path !== '/' && !allowedPatientRoutes.find(path => to.path.startsWith(path)))
            return '/';
        if(!user.intaken && !to.path.includes('intake'))
            return '/intake';
    }

    return;
}

export default beforeEach;