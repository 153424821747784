
import { Collection } from '@/ts/interfaces/Collection';
import { defineComponent } from '@vue/runtime-core';
import { isLegend } from './helpers';
import { toggleSelected } from './module';

export default defineComponent({
    computed: {
        collections(){
            return this.$store.state.chooser!.collections;
        },
        selected(){
            return this.$store.state.chooser!.selected;
        }
    },
	methods: {
        activeAsCollection(collection: Collection){
            for(const id of collection.questions)
                if(!isLegend(id) && !this.selected.includes(id))
                    return false

            return true
        },
		activateCollection(event: Event, collection: Collection, asCollection = true){
            const target = event.target as HTMLElement;
            const button = (target.tagName === 'BUTTON' ? target : target.parentNode) as HTMLButtonElement;
            if(button.disabled)
                return false;

            if(asCollection)
                return toggleSelected({ id: `c${collection.id}` });
            
            const isActive = this.activeAsCollection(collection);
            for(const id of collection.questions)
                if(!isLegend(id) && (isActive || !this.selected.includes(id)))
                    toggleSelected({ id });
        },
	}
});
