import { namespacedToCommit, namespacedToDispatch } from "@/helpers/store";
import { treeNode, ViewQuestionnaireState } from "@/ts/state/ViewQuestionnaireState";
import { State } from "@/ts/state/State";
import { ActionContext } from "vuex";
import { isQuestion, Response } from "@/ts/interfaces/Question";
import { questionnaireEssentials } from "@/ts/interfaces/Questionnaire";
import { makeCompoundId } from "@/helpers";
import { sendRequest } from "@/SharedChart/main";
import { ResponseObj } from "@/ts/Request";
import { groupBy } from 'lodash';
import store from "@/store";


const mutations = {
    setQuestionTooltip(state: ViewQuestionnaireState, payload?: { text: string, event: Event }){
        state.questionTooltip = payload ? { text: payload.text, anchorEl: payload.event.target! } : null;
    },
    setSelectionTooltip(state: ViewQuestionnaireState, payload?: { node: treeNode, event: Event }){
        state.selectionTooltip = payload ? { node: payload.node, anchorEl: payload.event.target! } : null;
    },
    setResponses(state: ViewQuestionnaireState, responses: Response[]){
        state.responsesById = groupBy(responses, 'question_id');

        const { responsesByDate: byDate } = state;
        responses.forEach(response => {
            const created_at = response.created_at!;
            if(!byDate[created_at]) byDate[created_at] = {}
            
            byDate[created_at][makeCompoundId(response)] = response;
        });

        const allDates = responses
            // .filter(({ question_id }) => selection.find(item => item.id === question_id))
            .map(response => response.created_at!)
            .filter((date, index, dates) => dates.indexOf(date) === index)
            .sort((dateA, dateB) => new Date(dateA).getTime() - new Date(dateB).getTime());
        state.dates = {
            all: allDates,
            start: new Date(allDates[0] ?? Date.now()),
            end: new Date(allDates[24] ?? allDates[allDates.length - 1] ?? Date.now())
        }
    },
    setCurrentQuestion(state: ViewQuestionnaireState, currentQuestion: ViewQuestionnaireState['currentQuestion']){
        state.currentQuestion = currentQuestion;
    },
    setQuestionTree(state: ViewQuestionnaireState, questionTree: ViewQuestionnaireState['questionTree']){
        state.questionTree = questionTree;
    },
    setChartActivated(state: ViewQuestionnaireState, chartActivated: boolean){
        state.chartActivated = chartActivated;
    },
    toggleSelection(state: ViewQuestionnaireState, path: string){
        state.questions[path].selected = !state.questions[path].selected;
    },
    updateDates(state: ViewQuestionnaireState, updates: Partial<ViewQuestionnaireState['dates']>){
        Object.assign(state.dates, updates);
    }
}

type Context = ActionContext<ViewQuestionnaireState, State>;

const toCommit = namespacedToCommit('viewQuestionnaire');
const toDispatch = namespacedToDispatch('viewQuestionnaire');

export const setQuestionTooltip = toCommit(mutations.setQuestionTooltip);
export const setSelectionTooltip = toCommit(mutations.setSelectionTooltip);
export const setResponses = toCommit(mutations.setResponses);
export const setCurrentQuestion = toCommit(mutations.setCurrentQuestion);
export const setQuestionTree = toCommit(mutations.setQuestionTree);
export const toggleSelection = toCommit(mutations.toggleSelection);
export const setChartActivated = toCommit(mutations.setChartActivated);
export const updateDates = toCommit(mutations.updateDates);

const actions = {
    async loadData({ rootState, state }: Context){
        await new Promise<void>(resolve => {
            store.watch(
                state => state.chooser!.questions,
                questions => questions.length && resolve(),
                { immediate: true }
            );
        });

        try{
            const response: ResponseObj<{ questionnaires: questionnaireEssentials[], responses: Response[] }> = await sendRequest('/questions/responses');
            setResponses(response.body.responses);
            const { questionnaires } = response.body;

            const toChildren = (idList: (string | number)[], parent: treeNode, level: number) => idList.map(id => {
                const { collections, questions } = rootState.chooser!;
                const source = typeof id === 'string' && id.startsWith('c')
                    ? collections.find(c => `c${c.id}` === id)!
                    : questions.find(q => q.id == id)!;
                    
                const node: treeNode = {
                    id: id.toString(),
                    text: !isQuestion(source) ? source.name : source.text,
                    type: !isQuestion(source) ? 'collection' : 'question',
                    path: `${parent.path}/${id}`,
                    selected: false,
                    parent,
                    level
                };
                state.questions[node.path] = node;
                
                const children = !isQuestion(source) ? source.questions : source.tree?.all;
                if(children)
                    node.children = toChildren(children, node, level + 1);
                return node;
            });
            
            setQuestionTree(
                questionnaires.map(({ id, name, questions }) => {
                    const node: treeNode = { id, text: name, type: 'questionnaire', selected: false, level: 1, path: id };
                    state.questions[id] = node;
                    node.children = toChildren(questions, node, 2);
                    return node;
                })
            );
        }catch(error){
            console.error('Error loading response data:', error);
        }
    },
    jumpToDate({ state, getters }: Context, position: 'start' | 'end'){
        const { all: dates } = state.dates;
        updateDates({
            start: new Date((position === 'end' && dates[dates.length - 1 - getters.relevantDates.length]) || dates[0]),
            end: new Date((position === 'start' && dates[getters.relevantDates.length - 2]) || dates[dates.length - 1])
        });
    }
}

export const loadData = toDispatch(actions.loadData);
export const jumpToDate = toDispatch(actions.jumpToDate);

export default {
    namespaced: true,
    state: {
        questionTooltip: null,
        selectionTooltip: null,
        currentQuestion: null,
        questions: {},
        questionnaires: {},
        dates: { all: [], start: new Date(), end: new Date() },
        responsesById: {},
        responsesByDate: {},
        questionTree: [],
        chartActivated: false
    },
    mutations,
    actions
}
