<template>
    <div class='info'>
        <tooltip maxWidth='24rem'>
            <template #default='slotProps'>  
                <img
                    v-bind='slotProps'
                    :class='{ empty: !settingsSelected(true), eye: true }'
                    @mouseenter='popper($event)'
                    aria-describedby='tooltip'
                    :src='`/images/eye-${settingsSelected(true) ? "icon" : "empty"}.png`'
                />
            </template>
            <template #tooltip>
                <div v-if='set === null && settingsSelected()' class='infoTooltip'>
                    <div v-if='q.canBeTop'>
                        <span class='label'>קטגוריה</span>
                        <span class='colon'>:</span>
                        <span v-if='q.cat !== null'>{{ cats[q.cat] }}</span>
                        <span v-else>טרם נבחרה</span>
                    </div>
                    <div>
                        <span class='label'>סוג</span>
                        <span class='colon'>:</span>
                        <span v-if='q.type !== null'>
                            {{ types[q.type] }}
                            <span v-if='q.fileType !== null'>{{ fileTypes[q.fileType] }}</span>
                        </span>
                        <span v-else>טרם נבחר</span>
                    </div>
                    <div v-if='q.type === "num" && q.unit !== false'>
                        <span class='label'>יחידת מדידה</span>
                        <span class='colon'>:</span>
                        {{ units[q.unit] }}
                    </div>
                    <div v-if='q.allow_alt'>
                        <img class='altImg' src='/images/check-icon.png'/>
                        <span class='alt'>אפשרות</span>
                        <span v-if='isMultiChoice'>לסמן "אחר"</span>
                        <span v-else>לאמצעי אינפוט חלופי</span>
                    </div>
                    <div class='options' v-if='isMultiChoice'>
                        <span class='label'>תשובות אפשריות</span>
                        <ul v-if='q.options[0].val.length'>
                            <li v-for='(option, index) in q.options' v-show='option.val.length' :key='index'>
                                {{ option.val }}
                            </li>
                        </ul>
                        <span class='label' style='text-decoration: none' v-else>טרם נרשמו</span>
                    </div>
                </div>
                <span v-else-if='q.origin && q.getFrom==="tree"'>שאלה ממיקום אחר בעץ</span>
            </template>
        </tooltip>
        <div class='pos'>{{ index + 1 }}</div>
    </div>
</template>

<script lang='ts'>
import Popper, { popperOptions } from '../../../CustomPopper';
import { defineComponent, PropType } from '@vue/runtime-core';
import { newQuestion } from '@/ts/state/NewQuestionsState';
import { isMultiChoice } from '@/helpers';

export default defineComponent({
    props: {
        q: { type: Object as PropType<newQuestion>, required: true },
        level: { type: Number, required: true },
        index: { type: Number, required: true }
    },
    computed: {
        isMultiChoice(){
            return this.q.type && isMultiChoice(this.q);
        },
        set(){
            return this.$store.state.questions!.set;
        },
        cats(){
            return this.$store.state.questions!.cats;
        },
        types(){
            return this.$store.state.questions!.types;
        },
        fileTypes(){
            return this.$store.state.questions!.fileTypes;
        },
        units(){
            return this.$store.state.questions!.units;
        }
    },
    methods: {
        popper(event: Event, open = true){
            const tooltip = `tooltip${this.level-1}-${this.index}`;
            if(this.set === null && open && this.settingsSelected(true))
                Popper.Show({
                    button: (event.target as HTMLElement).parentNode,
                    tooltip,
                    placement: 'right-start'
                } as popperOptions);
            else if(Popper.instance)
                Popper.Hide(tooltip);
        },
        settingsSelected(ifOrigin = false){ 
            if(this.q.getFrom !== 'new')
                return Boolean(ifOrigin && this.q.origin?.data);
            else
                return this.q.allow_alt || this.hasEither(this.q, ['cat', 'type', 'fileType']);
        },
        hasEither(obj: { [key: string]: any }, items: string[]){
            for(const item of items)
                if(obj[item] !== '')
                    return true;
            return false;
        }
    }
});
</script>

<style lang='scss' scoped>
.info {
    position: relative;
    margin-left: 0.4rem;
    margin-right: -0.3rem;
    @include flexbox(false,center,column);
}

.infoTooltip {
    width: 22rem;
    padding: 0.75rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;


    & .label { text-decoration: underline; }
    & .colon { margin-left: 0.5rem; }
    & .alt	 { margin: 0 0.3rem; }
    & .altImg {
        transform: translateY(0.5rem);
        width: 0.75rem;
        height: 0.75rem;
    }
    & .options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
        border: 1px #fff dotted;
        margin-top: 0.25rem;
        border-radius: 0.2rem;
        padding: 0.3rem 0.75rem;

        & .label { margin: 0 auto; }
    }
    & ul {
        list-style-type: disc;
        padding: 0 1rem;
        margin: -0.2rem 0;
        text-align: right;
    }
}

.eye {
    width: 2rem;
    &.empty {
        width: 1.5rem;
        margin: 4px;
        margin-top: 6px;
    }
}
</style>
