<template>
    <collapsible :withArrow='false' v-if='node.children?.length'>
        <template #toggle='toggleProps'>
            <toggle-button :node='node' v-bind='toggleProps' />
        </template>
        <div class='toggleContent'>
            <question-selection-item
                v-for='child in node.children'
                :node='child'
                :key='child.id'
            />
        </div>
    </collapsible>
    <toggle-button v-else :node='node' />
</template>

<script lang="ts">
import { treeNode } from "@/ts/state/ViewQuestionnaireState";
import { defineComponent, PropType } from "@vue/runtime-core";
import ToggleButton from './ToggleButton.vue';

export default defineComponent({
    props: {
        node: { type: Object as PropType<treeNode>, required: true }
    },
    components: { ToggleButton }
});
</script>

<style lang="scss" scoped>
    .toggleContent {
        padding: .5rem 0 .5rem .8rem;
        padding-left: 0;
        margin-right: 1.3rem;
        border-right: 2px solid #ccc;
    }
</style>