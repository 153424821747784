
import { defineComponent } from '@vue/runtime-core';
import PhysicianView from './PhysicianView.vue';
import QuestionnaireSelect from './QuestionnaireSelect.vue';

export default defineComponent({
    components: { PhysicianView, QuestionnaireSelect },
    computed: {
        isPhysician(){
            return this.$store.state.auth0.roles.includes('physician');
        },
        isPatient(){
            return this.$store.state.auth0.roles.includes('patient');
        }
    },
    methods: {
        remind(){
            fetch('http://localhost:3000/remind', {
                headers: {
                    'Authorization': 'Api-Key GxppZnhrhYj0R0Q1lxCcwF95NWLcSPwWVdBv4XhhU1LcZeHVqthk9byJOJQITYCWfbtg9Fqy',
                    'X-TIMEZONE-OFFSET': new Date().getTimezoneOffset().toString()
                },
                credentials: 'include',
                method: 'put'
            })
        }
    }
});
