<template>
    <div id='questionFilters' class='card'>
        <label>
            <i class="material-icons-round">category</i>
            <custom-select v-model='category' :options='categoryList' />
        </label>
        <label>
            <i class="material-icons-round">search</i>
            <input :placeholder='`${$parse("חפש", "חפשי")} שאלה`' v-model='search' />
        </label>
    </div>
    <ul id='questionListWrapper'>
        <li
            v-for='question in questions'
            :id='question.id'
            :class='{ card: true, active: selected.includes(question.id) }'
            :key='question.id'
        >
            <i class='material-icons-round' @click='toggleSelected({ id: question.id })'>
                {{ !selected.includes(question.id) ? 'add_circle_outline' : 'remove_circle_outline' }}
            </i>
            {{ $parse(question.text) }}
            <!-- <img
                v-if='$route.path.includes("follow") && question.hasChildren'
                src='/images/tree-icon.png'
                class='tree'
                @click.stop
            /> -->
        </li>
    </ul>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import questionCategories from '../../assets/json/questionCategories.json'
import { toggleSelected } from './module';

export default defineComponent({
    data: () => ({ category: 'all', search: '' }),
    computed: {
        questions(){
            const { questions, questionsByCat } = this.$store.state.chooser!;
            const all = this.category === 'all' ? questions : Object.values(questionsByCat[this.category]);
            return all.filter(question => question.cat !== null && (!this.search.length || question.text.includes(this.search)))
        },
        categoryList(){
            const { questionsByCat } = this.$store.state.chooser!;
            const categories = Object.entries(questionCategories).filter(([cat]) => questionsByCat[cat])
            return [['all', 'כל הקטגוריות']].concat(categories)
        },
        selected(){
            return this.$store.state.chooser!.selected;
        }
    },
    methods: {
        toggleSelected
    }
});
</script>

<style lang="scss" scoped>
#questionFilters {
    padding: .75rem 1.25rem;
    margin-top: .2rem;
    margin-bottom: 4rem;
    border: none;
    background-color: rgba(255, 255, 255, .7);
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    & label {
        display: flex;
        align-items: center;
        margin: 0;
    }

    & i {
        margin-left: .5rem;
    }
}

#questionListWrapper {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;

    & > li {
        padding: .25rem 2rem .25rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;

        & i {
            font-size: 1.1rem;
            position: absolute;
            right: .25rem;
            cursor: pointer;
        }
        
        &:hover {
            background-color: $swatchC;
        }

        &.active {
            background-color: $hoverC;
        }
    }
}
</style>
