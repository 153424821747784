<template>
    <section>
        <div class='daily card'>
            <h1 :class='{ [title.class]: true }'>{{ title.text }}</h1>
            <template v-if='!byTime'>
                <div v-for='item in 3' :key='item' class='dailyItem' loading>
                    <div><skeleton width='8rem' :speed='1.4' /></div>
                    <div><skeleton width='12rem' :speed='1.4' /></div>
                </div>
            </template>
            <template v-for='[time, items] in byTime'>
                <div
                    v-for='item in items'
                    @click='loadQuestionnaire(time, item.id)'
                    :key='item.id'
                    class='dailyItem'
                    :aria-disabled='time-1 > hour || undefined'
                >
                    <div>{{ item.name }}</div>
                    <div class='allowedAt'>
                        {{ time > 0
                            ? `ניתן למלא בין ${time - 1}:00 ל-${parseInt(time) + 2}:00`
                            : `ניתן למלא בכל שעות היום`
                        }}
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script lang='ts'>
import { questionnairesResponse, RawQuestionnaire, timeEntry } from '@/ts/interfaces/Questionnaire';
import { defineComponent } from '@vue/runtime-core';
import chatModule, { setQuestionnaireMetadata, setQuestions } from '../../Chat/module';
import { setToast } from '@/store';

const generateResponses = false;

type data = { hour: number, questionnaires: RawQuestionnaire[], byTime: [number | 'any', RawQuestionnaire[]][] | null };

export default defineComponent({
    async beforeMount(){
        try{
            const { body: { questionnaires, byTime } }: questionnairesResponse = await this.$request('/questionnaires/current');
            Object.assign(this, {
                questionnaires,
                byTime: byTime!.map(([time, entry]: timeEntry) => [
                    time,
                    entry.map(questionnaireId => questionnaires.find(q => q.id === questionnaireId ))
                ])
            });
        }catch(error){
            console.error(error);
        }
    },
    data: (): data => ({ hour: new Date().getHours(), questionnaires: [], byTime: null }),
    computed: {
        title(){
            if(!this.byTime){
                return { text: 'טוען שאלונים...' };
            }else if(!Object.keys(this.byTime).length){
                return { text: 'אין שאלונים למילוי כרגע', class: 'noneToFill' };
            }else{
                 return { text: 'שאלונים למילוי היום' };
            }
        }
    },
    methods: {
        async loadQuestionnaire(time: number, questionnaireId: string){
            if(time - 1 > this.hour)
                return
            
            try{
                const { body: questions } = await this.$request('/questions', 'put', { questionnaireId });

                if(generateResponses)
                    return import('../../Chat/ResponseGenerator').then(({ default: ResponseGenerator }) => new ResponseGenerator(200, questions));

                if(!this.$store.hasModule('chat'))
                    this.$store.registerModule('chat', chatModule);

                setQuestions(questions);
                setQuestionnaireMetadata({ id: questionnaireId, time });
                this.$router.push(`/respond`);
            }catch(error){
                console.error(error);
               setToast({ text: 'משהו השתבש. נסו שוב', type: 'error' });
            }
        }
    }
});
</script>

<style lang="scss">
#dailyEmpty, .daily {
    margin: 2rem auto;
    width: 33rem;
}

#dailyEmpty {
    border-radius: 0.3rem;
    background: #f2cbcb;
}

.daily {
    & h1 {
        display: flex;
        justify-content: center;
    }

    & .dailyItem {
        display: flex;
        justify-content: space-between;
        padding: 1rem 1.5rem;
        border-bottom: $borderLight;
        min-height: 3.6rem;

        & > div {
            display: flex;
            align-items: center;
        }

        & img {
            width: 1rem;
            margin-left: 0.5rem;
        }

        & :last-child {
            color: #888;
        }

        &:hover:not([loading]):not([aria-disabled]) {
            background-color: $swatchB;
            cursor: pointer;
        }

        &[aria-disabled] {
            background-color: #f9f9f9;
            cursor: not-allowed;
        }
    }
}
</style>