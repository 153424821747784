<template>
    <div class='title' :style='{ gridRowStart: level }'>
        רמה {{ level }}
        <div class='count'>{{ howmany }}</div>
        <div class='increment' @click='addQuestion'>+</div>
    </div>
    <div class='arrow right' @click='shift(-1)' :style='{ gridRowStart: level }'>
        <span v-if='showRightArrow' class='material-icons-round'>keyboard_arrow_right</span>
    </div>
    <tree-item
        v-for='(question, index) in paginated'
        :question='question'
        :key='index + pagination'
        :pagination='pagination'
        :index='index + pagination'
        @textChange='text => setQuestion({ level: level - 1, index: index + pagination, prop: "text", val: text })'
    />
    <div class='arrow left' @click='shift(1)' :style='{ gridRowStart: level }'>
        <span v-if='showLeftArrow' class='material-icons-round'>keyboard_arrow_right</span>
    </div>
</template>

<script lang='ts'>
import { newQuestion } from '@/ts/state/NewQuestionsState';
import { computed, defineComponent, PropType } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { setQuestion ,addQuestion, getChildren, refreshTree, shift } from './module';
import TreeItem from './TreeItem.vue';

type child = newQuestion | { placeholder: true };
export function isNewQuestion(child: child): child is newQuestion {
  return child && (child as newQuestion).children !== undefined;
}
export type treeRow = { level: number, children: child[], parent: newQuestion | undefined };

export default defineComponent({
    props: {
        level: { type: Number, required: true },
        children: { type: Array as PropType<treeRow['children']>, required: true },
        parent: { type: Object as PropType<treeRow['parent']>, required: false }
    },
    components: { TreeItem },
    setup(props){
        const store = useStore();
        const pagination = computed(() => store.state.questions!.pagination[props.level - 1]);
        const shiftRow = (dir: 1 | -1, shiftTo: number) => {
            const level = props.level - 1;
            const { activePath, pagination } = store.state.questions!;
            shift({ level, dir, shiftTo })
            if(!shiftTo && (activePath[level] < pagination[level] || activePath[level] > pagination[level] + 2))
                refreshTree(level);
        }

        return {
            activePath: computed(() => store.state.questions!.activePath),
            questions: computed(() => store.state.questions!.questions),
            showRightArrow: computed(() => props.children.length > 3 && pagination.value > 0),
            showLeftArrow: computed(() => props.children.length > 3 && props.children.length != pagination.value + 3),
            howmany: computed(() => {
                const count = props.children.length;
                return count === 1 ? 'שאלה אחת' : `${count} שאלות`;
            }),
            paginated: computed(() => {
                const paginated = props.children.slice(pagination.value, pagination.value + 3);
                while(paginated.length < 3) paginated.push({ placeholder: true });
                return paginated;
            }),
            pagination,

            setQuestion,
            addQuestion(){
                const level = props.level - 1;
                const children = getChildren(store.state.questions!, level);
                addQuestion({ level, children });
                refreshTree(level);
                if(children.length > 3)
                    shiftRow(1, children.length - 3);
            },
            shift: shiftRow
        }
    }
});
</script>

<style lang='scss' scoped>
.title {
		@include flexbox(center-all,column);
		font-weight: bold;
    margin: auto auto;
    
    & .count {
        border: 1px #ccc solid;
        border-radius: $corner;
        padding: 0.3rem;
        min-width: 4rem;
        font-weight: normal;
        text-align: center;
    }
    & .increment {
        @include flexbox(center-all);
        box-shadow: 0 0.125rem 0.25rem #ccc;
        border-radius: 25px;
        border: 1px solid #ffebeb;
        background-color: #fff;

        width: 1.5rem;
        height: 1.5rem;
        margin: 0.3rem 0.7rem;
        font-weight: bold;
        cursor: pointer;
    }
}
.arrow {
    cursor: pointer;
    margin: auto;
    @include flexbox(center-all);
    transition: transform .1s;
    --arrow-rotation: 0deg;
    --arrow-scale: 1;
    transform: scale(var(--arrow-scale)) rotate(var(--arrow-rotation));

    &:hover {
        --arrow-scale: 1.2;
    }

    &.left {
        grid-column-start: 6;
        --arrow-rotation: 180deg;
    }

    & .material-icons-round {
        transform: scaleY(1.7);
        cursor: inherit;
    }
}
</style>