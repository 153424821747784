
import Popper, { popperOptions } from '../../../CustomPopper';
import { defineComponent, PropType } from '@vue/runtime-core';
import { newQuestion } from '@/ts/state/NewQuestionsState';
import { isMultiChoice } from '@/helpers';

export default defineComponent({
    props: {
        q: { type: Object as PropType<newQuestion>, required: true },
        level: { type: Number, required: true },
        index: { type: Number, required: true }
    },
    computed: {
        isMultiChoice(){
            return this.q.type && isMultiChoice(this.q);
        },
        set(){
            return this.$store.state.questions!.set;
        },
        cats(){
            return this.$store.state.questions!.cats;
        },
        types(){
            return this.$store.state.questions!.types;
        },
        fileTypes(){
            return this.$store.state.questions!.fileTypes;
        },
        units(){
            return this.$store.state.questions!.units;
        }
    },
    methods: {
        popper(event: Event, open = true){
            const tooltip = `tooltip${this.level-1}-${this.index}`;
            if(this.set === null && open && this.settingsSelected(true))
                Popper.Show({
                    button: (event.target as HTMLElement).parentNode,
                    tooltip,
                    placement: 'right-start'
                } as popperOptions);
            else if(Popper.instance)
                Popper.Hide(tooltip);
        },
        settingsSelected(ifOrigin = false){ 
            if(this.q.getFrom !== 'new')
                return Boolean(ifOrigin && this.q.origin?.data);
            else
                return this.q.allow_alt || this.hasEither(this.q, ['cat', 'type', 'fileType']);
        },
        hasEither(obj: { [key: string]: any }, items: string[]){
            for(const item of items)
                if(obj[item] !== '')
                    return true;
            return false;
        }
    }
});
