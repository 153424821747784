<template>
    <div id='chooserSidebar'>
        <h2>השאלות שנבחרו</h2>
        <ul id='chosenQuestions'>
            <li v-if='!selected.length' id='noneSelected'>טרם נבחרו שאלות</li>
            <li v-for='(id, index) in selected' :key='id'>
                <div>
                    <div id='orderIcons'>
                        <i class='material-icons-round' :disabled='isDisabled(index, -1)' @click='!isDisabled(index, -1) && reorderSelected({ index, dir: -1 })'>
                            expand_less
                        </i>
                        <i class='material-icons-round' :disabled='isDisabled(index, 1)' @click='!isDisabled(index, 1) && reorderSelected({ index, dir: 1 })'>
                            expand_more
                        </i>
                    </div>
                    <span v-if='!isLegend(id)'>
                        {{ typeof id === 'string' && id.startsWith('c')
                            ? 'שאלון: ' + collections.find(c => 'c' + c.id === id)?.name
                            : $parse(questions.find(q => q.id === id)?.text)
                        }}
                    </span>
                    <input v-else v-model='legends[id.replace("l", "")]' placeholder='הערה מקדימה' />
                </div>
                <div v-if='!isLegend(id)'>
                    <i class='material-icons-round remove-icon' @click='toggleSelected({ id })'>
                        remove_circle_outline
                    </i>
                    <i v-if='allowLegends' class='material-icons-round add-legend-icon' @click='addLegend(index)'>
                        post_add
                    </i>
                </div>
                <div v-else>
                    <i class='material-icons-round remove-icon' @click='deleteLegend(index)'>
                        remove_circle_outline
                    </i>
                </div>
            </li>
        </ul>
        <submit-button :text='submitText' :submitCallback='() => $emit("submit")' :immediateLoading='true' />
        <!-- <slot></slot> -->
    </div>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import { isLegend } from './helpers';
import { addLegend, deleteLegend, reorderSelected, toggleSelected } from './module';

// /(^q?[0-9]+)/.test(item)
export default defineComponent({
    props: {
        submitText: String,
        allowLegends: Boolean
    },
    emits: ['submit'],
    computed: {
        selected(){ return this.$store.state.chooser!.selected },
        questions(){ return this.$store.state.chooser!.questions },
        legends(){ return this.$store.state.chooser!.legends },
        collections(){ return this.$store.state.chooser!.collections },
    },
    methods: {
        toggleSelected,
        reorderSelected,
        addLegend,
        deleteLegend,
        isDisabled(index: number, dir: number){
            if(dir < 0 && !index)
                return 'disabled';
            if(dir > 0 && index === this.selected.length - 1)
                return 'disabled';
        },
        isLegend
    }
});
</script>

<style lang="scss">
#chooserSidebar {
    width: 30vw;
    max-width: 30rem;
    min-width: 20rem;
    flex-shrink: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    position: sticky;
    top: 0;
    z-index: 3000;
    border-right: $borderLight;
    box-shadow: 2px 4px 12px 0px #aaa inset;

    @include flexbox(false, stretch, column);

    & button {
        margin: 0 !important;
        box-shadow: none;
        border-radius: 0;
        border-top: $borderLight;
        height: 3rem;
        background-color: $swatchB;

        &:hover {
            background-color: $hoverB;
        }
    }

    & img {
        max-width: 1.5rem;
        margin-left: 0.5rem;
        filter: grayscale(60%) brightness(110%);
    }

    & h2 {
        background-color: $swatchA;
        border-bottom: $borderLight;
        height: 3rem;
    }

    & #chosenQuestions {
        height: calc(100vh - 6rem - 64px);
        padding: 1rem 4rem 0.5rem 3rem;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        flex-grow: 0;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 0.5rem;
            &-thumb {
                border-radius: $corner;
            }
        }

        & #noneSelected {
            margin: 0 auto;
        }

        & li {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > div {
                display: flex;
                align-items: center;
            }

            & .remove-icon {
                font-size: 1.1rem;
                margin-left: 0.1rem;
            }

            & .add-legend-icon {
                margin-right: 0.5rem;
            }
        }

        & i {
            cursor: pointer;
        }

        & #orderIcons {
            display: flex;
            flex-direction: column;
            margin-left: 1rem;
            user-select: none;
            
            & i {
                margin: -0.2rem 0;
                &:hover:not([disabled]) {
                    transform: scale(1.2);
                }

                &[disabled] {
                    color: #ccc;
                    cursor: not-allowed;
                }
            }
        }
    }

	// & .inputs {
	// 	@extend .chosen;
	// 	padding: 0.5rem 1rem;
	// 	margin-bottom: 7rem;
	// 	@include flexbox(center,column);

	// 	& span { padding: 0.5rem 0; }
	// 	& input[type='radio'] { margin-left: 0.5rem; margin-right: 2rem }
	// 	& button {
	// 		&.submit { margin: 2.5rem auto 0 !important }
	// 		&.order { margin: 0 auto 1rem !important }
	// 	}
	// 	& button ~ span { text-align: center; }
	// }
}
</style>