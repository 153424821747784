<template>
    <ul class='categorizedList chooser collections'>
        <li v-for='c of collections' :key='c.id'>
            <div :id='`c${c.id}`'>
                <div>{{ c.name }}</div>
                <div>
                    <button type='button' class='asCollection' :class='{ active: selected.includes(`c${c.id}`) }'
                        @click='activateCollection($event, c)'
                    >
                        <img src='/images/questionnaire.jpg'/>
                    </button>
                    <button
                        type='button'
                        :class='{ asCollection: true, active: activeAsCollection(c) }'
                        @click='activateCollection($event, c, false)'
                    >
                        <img src='/images/collect.jpg'/>
                    </button>
                </div>
            </div>
        </li>
    </ul>
</template>

<script lang='ts'>
import { Collection } from '@/ts/interfaces/Collection';
import { defineComponent } from '@vue/runtime-core';
import { isLegend } from './helpers';
import { toggleSelected } from './module';

export default defineComponent({
    computed: {
        collections(){
            return this.$store.state.chooser!.collections;
        },
        selected(){
            return this.$store.state.chooser!.selected;
        }
    },
	methods: {
        activeAsCollection(collection: Collection){
            for(const id of collection.questions)
                if(!isLegend(id) && !this.selected.includes(id))
                    return false

            return true
        },
		activateCollection(event: Event, collection: Collection, asCollection = true){
            const target = event.target as HTMLElement;
            const button = (target.tagName === 'BUTTON' ? target : target.parentNode) as HTMLButtonElement;
            if(button.disabled)
                return false;

            if(asCollection)
                return toggleSelected({ id: `c${collection.id}` });
            
            const isActive = this.activeAsCollection(collection);
            for(const id of collection.questions)
                if(!isLegend(id) && (isActive || !this.selected.includes(id)))
                    toggleSelected({ id });
        },
	}
});
</script>
