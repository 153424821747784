import { makeSendRequest } from "@/helpers";
import { updateAuth0 } from "@/store";
import { Auth0State, role } from "@/ts/state/Auth0State";
import createAuth0Client, { Auth0Client, PopupConfigOptions, PopupLoginOptions } from "@auth0/auth0-spa-js";
import { App } from "@vue/runtime-core";
import { Router } from "vue-router";

export const auth0: { client: Auth0Client | null } = { client: null }

export const FAKE_AUTH0 = false;

export const Auth0Plugin = (router: Router) => ({
    async install(app: App) {
        if(FAKE_AUTH0)
            return updateAuth0({"loading":false,"isAuthenticated":true,"user":{"http://sharedchart.care/auth/roles":["physician"],"nickname":"sharedchart.care","name":"sharedchart.care@gmail.com","picture":"https://s.gravatar.com/avatar/f84f6cd6ee5d126b14e968e137dbaf1d?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fsh.png","updated_at":"2021-12-27T12:16:53.909Z","email":"sharedchart.care@gmail.com","email_verified":true,"sub":"auth0|61f2675270ad3d314074e242"},"popupOpen":false,"error":null,"roles":["physician"]});

        const client = await createAuth0Client({
            domain: 'shared-chart.eu.auth0.com', // should come from .ENV
            client_id: 'U6HhL69il98vbU8ZwX6RxAAnjrucpuOs', // should come from .ENV
            redirect_uri: window.location.origin,
            audience: 'https://api.sharedchart.care',
            scope: 'roles'
            // useRefreshTokens: true
        });

        const methods = {
            async loginWithPopup(options?: PopupLoginOptions, config?: PopupConfigOptions) {
                updateAuth0({ popupOpen: true });
            
                try {
                    await client.loginWithPopup(options, config);
                    updateAuth0({
                        isAuthenticated: await client.isAuthenticated(),
                        user: await client.getUser(),
                        error: null,
                        popupOpen: false
                    });
                }catch(error){
                    updateAuth0({ error: error as object, popupOpen: false });
                }
            },
            async handleRedirectCallback() {
                updateAuth0({ loading: true });
                try {
                    await client.handleRedirectCallback();
                    updateAuth0({
                        user: await client.getUser(),
                        isAuthenticated: true,
                        error: null,
                        loading: false
                    })
                }catch(error){
                    updateAuth0({ error: error as object, loading: false });
                }
            }
        }

        auth0.client = app.config.globalProperties.$auth0 = new Proxy(client, {
            get(client, property){
                if(property in methods)
                    return methods[property as keyof typeof methods];
                else
                    return client[property as keyof typeof client];
            }
        });

        try {
            // If the user is returning to the app after authentication...
            if (window.location.search.includes("code=") && window.location.search.includes("state=")){
                await client.handleRedirectCallback();
                router.replace('/');
            }

            updateAuth0({ error: null });
        }catch(error){
            updateAuth0({ error: error as object });
        }finally{
            const namespace = 'http://sharedchart.care/auth';
            const isAuthenticated = await client.isAuthenticated();
            
            const claims = isAuthenticated ? await client.getIdTokenClaims() : {} as any;

            let roles: role[] = claims[`${namespace}/roles`]?.map((role: string) => role.toLowerCase()) ?? [];
            let impersonating: Auth0State['impersonating'] = null;
            const impersonate = localStorage.getItem('impersonate');
            if(roles.includes('admin') && impersonate){
                const user = await makeSendRequest([async headers => headers.append('Authorization', 'Bearer ' + (await auth0.client!.getTokenSilently()))])(`/user/${impersonate}`);
                impersonating = user.body;
                roles = [impersonating!.role];
            }

            updateAuth0({
                isAuthenticated,
                user: await client.getUser(),
                loading: false,
                roles,
                impersonating
            });
        }
    }
});