
import { newQuestion } from '@/ts/state/NewQuestionsState';
import { computed, defineComponent, PropType } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { setQuestion ,addQuestion, getChildren, refreshTree, shift } from './module';
import TreeItem from './TreeItem.vue';

type child = newQuestion | { placeholder: true };
export function isNewQuestion(child: child): child is newQuestion {
  return child && (child as newQuestion).children !== undefined;
}
export type treeRow = { level: number, children: child[], parent: newQuestion | undefined };

export default defineComponent({
    props: {
        level: { type: Number, required: true },
        children: { type: Array as PropType<treeRow['children']>, required: true },
        parent: { type: Object as PropType<treeRow['parent']>, required: false }
    },
    components: { TreeItem },
    setup(props){
        const store = useStore();
        const pagination = computed(() => store.state.questions!.pagination[props.level - 1]);
        const shiftRow = (dir: 1 | -1, shiftTo: number) => {
            const level = props.level - 1;
            const { activePath, pagination } = store.state.questions!;
            shift({ level, dir, shiftTo })
            if(!shiftTo && (activePath[level] < pagination[level] || activePath[level] > pagination[level] + 2))
                refreshTree(level);
        }

        return {
            activePath: computed(() => store.state.questions!.activePath),
            questions: computed(() => store.state.questions!.questions),
            showRightArrow: computed(() => props.children.length > 3 && pagination.value > 0),
            showLeftArrow: computed(() => props.children.length > 3 && props.children.length != pagination.value + 3),
            howmany: computed(() => {
                const count = props.children.length;
                return count === 1 ? 'שאלה אחת' : `${count} שאלות`;
            }),
            paginated: computed(() => {
                const paginated = props.children.slice(pagination.value, pagination.value + 3);
                while(paginated.length < 3) paginated.push({ placeholder: true });
                return paginated;
            }),
            pagination,

            setQuestion,
            addQuestion(){
                const level = props.level - 1;
                const children = getChildren(store.state.questions!, level);
                addQuestion({ level, children });
                refreshTree(level);
                if(children.length > 3)
                    shiftRow(1, children.length - 3);
            },
            shift: shiftRow
        }
    }
});
