
import { defineComponent } from '@vue/runtime-core';
import { isLegend } from './helpers';
import { addLegend, deleteLegend, reorderSelected, toggleSelected } from './module';

// /(^q?[0-9]+)/.test(item)
export default defineComponent({
    props: {
        submitText: String,
        allowLegends: Boolean
    },
    emits: ['submit'],
    computed: {
        selected(){ return this.$store.state.chooser!.selected },
        questions(){ return this.$store.state.chooser!.questions },
        legends(){ return this.$store.state.chooser!.legends },
        collections(){ return this.$store.state.chooser!.collections },
    },
    methods: {
        toggleSelected,
        reorderSelected,
        addLegend,
        deleteLegend,
        isDisabled(index: number, dir: number){
            if(dir < 0 && !index)
                return 'disabled';
            if(dir > 0 && index === this.selected.length - 1)
                return 'disabled';
        },
        isLegend
    }
});
