
import { defineComponent } from '@vue/runtime-core';
import chooserModule, { load } from '../Chooser/module';

export default defineComponent({
    beforeMount(){
        if(this.$store.hasModule('chooser')) return;
        
        this.$store.registerModule('chooser', chooserModule);
        load();
    }
});
