
import { toggleChildren, toggleParent } from "./helpers";
import { treeNode } from "@/ts/state/ViewQuestionnaireState";
import { defineComponent, PropType } from "@vue/runtime-core";
import { setQuestionTooltip, setSelectionTooltip, toggleSelection } from '../module';

export default defineComponent({
    props: {
        classes: { type: Object },
        toggle: { type: Function },
        isActive: { type: Boolean },
        node: { type: Object as PropType<treeNode>, required: true }
    },
    data: () => ({ tooltipTimeout: null as any }),
    computed: {
        preparedText(){
            const parsed = this.$parse(this.node.text);
            const maxLen = 48;
            if(parsed.length > maxLen)
                return { long: parsed, short: parsed.slice(0, maxLen).trim() + '...' };
            
            return { short: parsed };                
        },
        bulletBorderRadius(){
            return (this.node.level % 2 ? '6px' : '50%') + ' !important';
        }
    },
    methods: {
        hideQuestionTooltip(){
            if(this.tooltipTimeout){
                clearTimeout(this.tooltipTimeout);
                this.tooltipTimeout = null;
            }else if(this.$store.state.viewQuestionnaire?.questionTooltip){
                setQuestionTooltip();
            }
        },
        showQuestionTooltip(event: Event){
            if(this.preparedText.long)
                this.tooltipTimeout = setTimeout(() => {
                    setQuestionTooltip({ text: this.preparedText.long!, event });
                    this.tooltipTimeout = null;
                }, 600);
        },
        hideSelectionTooltip(){
            if(this.$store.state.viewQuestionnaire?.selectionTooltip)
                setSelectionTooltip();
        },
        toggleSelection(event: Event){
            if(this.node.type === 'question' && this.node.children?.length){
                if(this.$store.state.viewQuestionnaire!.selectionTooltip?.node !== this.node)
                    return setSelectionTooltip({ node: this.node, event });
                else
                    return setSelectionTooltip();
            }
            
            toggleSelection(this.node.path);
            toggleChildren(this.node.children, this.node.selected);
            toggleParent(this.node.parent, this.node.selected);
        }
    }
});
