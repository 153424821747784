
import { questionnairesResponse, RawQuestionnaire, timeEntry } from '@/ts/interfaces/Questionnaire';
import { defineComponent } from '@vue/runtime-core';
import chatModule, { setQuestionnaireMetadata, setQuestions } from '../../Chat/module';
import { setToast } from '@/store';

const generateResponses = false;

type data = { hour: number, questionnaires: RawQuestionnaire[], byTime: [number | 'any', RawQuestionnaire[]][] | null };

export default defineComponent({
    async beforeMount(){
        try{
            const { body: { questionnaires, byTime } }: questionnairesResponse = await this.$request('/questionnaires/current');
            Object.assign(this, {
                questionnaires,
                byTime: byTime!.map(([time, entry]: timeEntry) => [
                    time,
                    entry.map(questionnaireId => questionnaires.find(q => q.id === questionnaireId ))
                ])
            });
        }catch(error){
            console.error(error);
        }
    },
    data: (): data => ({ hour: new Date().getHours(), questionnaires: [], byTime: null }),
    computed: {
        title(){
            if(!this.byTime){
                return { text: 'טוען שאלונים...' };
            }else if(!Object.keys(this.byTime).length){
                return { text: 'אין שאלונים למילוי כרגע', class: 'noneToFill' };
            }else{
                 return { text: 'שאלונים למילוי היום' };
            }
        }
    },
    methods: {
        async loadQuestionnaire(time: number, questionnaireId: string){
            if(time - 1 > this.hour)
                return
            
            try{
                const { body: questions } = await this.$request('/questions', 'put', { questionnaireId });

                if(generateResponses)
                    return import('../../Chat/ResponseGenerator').then(({ default: ResponseGenerator }) => new ResponseGenerator(200, questions));

                if(!this.$store.hasModule('chat'))
                    this.$store.registerModule('chat', chatModule);

                setQuestions(questions);
                setQuestionnaireMetadata({ id: questionnaireId, time });
                this.$router.push(`/respond`);
            }catch(error){
                console.error(error);
               setToast({ text: 'משהו השתבש. נסו שוב', type: 'error' });
            }
        }
    }
});
